<template>
  <div>
    <a-badge :color="color"></a-badge>
    <span :style="{ color: color }">{{ $t(`project_status.${status}`) }}</span>
  </div>
</template>

<script>
export default {
  name: 'ProjectStatusBadge',
  props: {
    status: { type: String, default: 'unknown' }
  },
  computed: {
    color () {
      switch (this.status) {
        case 'pending': return '#7499ff'
        case 'active': return '#52c41a'
        case 'expired': return '#f5222d'
        default: return '#97aab3'
      }
    }
  }
}
</script>
