import { render, staticRenderFns } from "./ProjectStatusBadge.vue?vue&type=template&id=7870ec31&"
import script from "./ProjectStatusBadge.vue?vue&type=script&lang=js&"
export * from "./ProjectStatusBadge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports