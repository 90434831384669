<template>
  <div>
    <a-form-model :model="form">
      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="zone">
            <zone-select v-model="form.zone" :label-in-value="true"></zone-select>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item prop="name">
            <a-input v-model="form.name" :allow-clear="true" :max-length="128" placeholder="请输入系统名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xs="24" :sm="12" :xl="4">
          <a-form-model-item>
            <a-button icon="search" type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-card :bordered="false">
      <a-space style="margin-bottom: 16px;">
        <a-button icon="plus" type="primary" @click="$refs.createForm.show()">创建</a-button>
        <create-form ref="createForm" @ok="() => { this.form.page = 1; this.fetch() }"></create-form>
      </a-space>

      <a-table :columns="columns" :data-source="dataSource" :loading="loading" :pagination="false" row-key="id"
        :row-selection="rowSelection" :scroll="{ x: scrollX }" @change="change" style="margin-bottom: 16px;">
        <template slot="name" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record.name">
            <a @click="$router.push({ name: 'SystemDetail', params: { id: record.id } })">{{ record.name }}</a>
          </a-tooltip>
        </template>
        <template slot="zone" slot-scope="record">
          <a-tooltip placement="topLeft">
            <template slot="title" v-if="record">{{ record.name }}</template>
            {{ record ? record.name : '-' }}
          </a-tooltip>
        </template>
        <template slot="charger" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record.nickname">{{ record.nickname }}</a-tooltip>
        </template>
        <template slot="builder" slot-scope="record">
          <a-tooltip placement="topLeft" :title="record.nickname">{{ record.nickname }}</a-tooltip>
        </template>
        <template slot="status" slot-scope="status">
          <project-status-badge :status="status"></project-status-badge>
        </template>
        <template slot="operation" slot-scope="record">
          <a @click="$refs.authorizeForm.show(record.id)">
            <a-space style="user-select: none;">
              <a-icon type="user-add"></a-icon>
              <span>授权</span>
            </a-space>
          </a>
          <a-divider type="vertical"></a-divider>
          <a @click="$refs.updateForm.show(record.id)" style="color: #faad14;">
            <a-space style="user-select: none;">
              <a-icon type="edit"></a-icon>
              <span>编辑</span>
            </a-space>
          </a>
          <a-divider type="vertical"></a-divider>
          <a-popconfirm ok-type="danger" title="是否确定删除？" @confirm="confirm(record.id)">
            <a-icon slot="icon" type="question-circle-o" style="color: #f5222d;"></a-icon>
            <a style="color: #f5222d;">
              <a-space style="user-select: none;">
                <a-icon type="delete"></a-icon>
                <span>删除</span>
              </a-space>
            </a>
          </a-popconfirm>
        </template>
      </a-table>
      <authorize-form ref="authorizeForm" @ok="fetch"></authorize-form>
      <update-form ref="updateForm" @ok="fetch"></update-form>

      <div style="height: 32px;">
        <span v-if="rowSelection.selectedRowKeys.length" style="line-height: 32px;">{{ `已选中
                  ${rowSelection.selectedRowKeys.length} 条`
        }}</span>
        <a-pagination v-model="form.page" :pageSize="form.pageSize" :show-size-changer="true"
          :show-total="t => `共 ${t} 条`" :total="total" @change="page => { this.form.page = page; this.fetch() }"
          @showSizeChange="(_, pageSize) => { this.form.page = 1; this.form.pageSize = pageSize; this.fetch() }"
          style="float: right;"></a-pagination>
      </div>
    </a-card>
  </div>
</template>

<script>
import { deleteProject, getProjectList } from '@/api/project'
import ProjectStatusBadge from '../modules/ProjectStatusBadge'
import UpdateForm from '../modules/UpdateForm'
import AuthorizeForm from './modules/AuthorizeForm'
import CreateForm from './modules/CreateForm'
import ZoneSelect from '@/components/select/ZoneSelect.vue'

export default {
  name: 'SystemList',
  components: {
    AuthorizeForm,
    CreateForm,
    ProjectStatusBadge,
    UpdateForm,
    ZoneSelect
  },
  data () {
    return {
      form: {
        name: '',
        page: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '系统名称',
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: 'name' }
        },
        {
          dataIndex: 'zone',
          title: '所属域',
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: 'zone' }
        },
        {
          dataIndex: 'charger',
          title: '系统负责人',
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: 'charger' }
        },
        {
          dataIndex: 'builder',
          title: '建设负责人',
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: 'builder' }
        },
        { dataIndex: 'started_at', title: '系统开始时间', width: 200 },
        { dataIndex: 'ended_at', title: '系统截止时间', width: 200 },
        {
          dataIndex: 'status',
          title: '状态',
          width: 100,
          fixed: 'right',
          scopedSlots: { customRender: 'status' },
          filters: [
            { text: '等待', value: 'pending' },
            { text: '正常', value: 'active' },
            { text: '到期', value: 'expired' }
          ],
          filterMultiple: false
        },
        {
          title: '操作',
          width: 240,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: [],
      total: 0,
      loading: false,
      rowSelection: {
        selectedRowKeys: [],
        onChange: (selectedRowKeys) => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
        }
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach((column) => {
        sum += column.width
      })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.name) params.name = this.form.name
      if (this.form.zone) params.zone_id = this.form.zone.key
      this.loading = true
      getProjectList(params)
        .then((res) => {
          const data = res.data
          this.dataSource = data.data
          this.total = data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    search () {
      this.form.page = 1
      this.rowSelection.selectedRowKeys = []
      this.fetch()
    },
    change (_, filters) {
      this.form.status = filters.status[0]
      this.fetch()
    },
    confirm (id) {
      deleteProject(id).then((res) => {
        this.$message.success(res.message)
        if (this.total - 1 <= (this.form.page - 1) * this.form.pageSize) {
          if (this.form.page > 1) this.form.page -= 1
        }
        this.fetch()
      })
    }
  }
}
</script>

<style>
</style>
