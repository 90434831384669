<template>
  <a-modal
    :confirm-loading="loading"
    title="编辑系统授权"
    :visible="visible"
    :width="720"
    @ok="ok"
    @cancel="reset"
  >
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="授权用户" prop="authorized_users">
        <user-select v-model="form.authorized_users" :label-in-value="true" mode="multiple" placeholder="请选择授权用户"></user-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  getProjectAuthorizedUserList,
  updateProject
} from '@/api/project'
import UserSelect from '@/components/select/UserSelect'

export default {
  name: 'AuthorizeForm',
  components: { UserSelect },
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        authorized_users: []
      },
      rules: {
        authorized_users: [{ message: '请选择授权用户', required: true, trigger: 'change' }]
      }
    }
  },
  methods: {
    show (id) {
      this.id = id
      getProjectAuthorizedUserList(id).then(res => {
        const data = res.data
        data.data.forEach(user => {
          this.form.authorized_users.push({ key: user.id, label: user.nickname })
        })
      }).finally(() => {
        this.visible = true
      })
    },
    reset () {
      this.form = { authorized_users: [] }
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          updateProject(this.id, {
            authorized_user_ids: this.form.authorized_users.map(user => user.key)
          }).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
