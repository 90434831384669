<template>
  <a-select
    :allow-clear="allowClear"
    :filter-option="false"
    :label-in-value="labelInValue"
    :mode="mode"
    :options="options"
    :placeholder="placeholder"
    :show-arrow="true"
    :show-search="true"
    :value="value"
    @change="v => $emit('input', v)"
    @popupScroll="popupScroll"
    @search="search"
  ></a-select>
</template>

<script>
import { getUserList } from '@/api/user'

export default {
  name: 'UserSelect',
  props: {
    allowClear: { type: Boolean, default: true },
    labelInValue: { type: Boolean, default: false },
    mode: { type: String, default: 'default' },
    placeholder: { type: String, default: '请选择用户' },
    value: { type: [String, Array, Object] }
  },
  data () {
    return {
      form: { page: 1, page_size: 20 },
      options: [],
      total: 0,
      timeout: null
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getUserList(this.form).then(res => {
        const data = res.data
        this.total = data.total
        data.data.forEach(user => {
          this.options.push({ key: user.id, label: user.nickname })
        })
      })
    },
    popupScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop === clientHeight) {
        if (this.total > this.form.page * this.form.page_size) {
          this.form.page += 1
          this.fetch()
        }
      }
    },
    search (value) {
      this.options = []
      this.form.nickname = value
      if (this.timeout !== null) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.fetch()
      }, 300)
    }
  }
}
</script>
