<template>
  <a-modal :confirm-loading="loading" title="编辑系统" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="系统名称" prop="name">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="所属域" prop="zone">
        <zone-select v-model="form.zone" placeholder="请选择所属域" :label-in-value="true"></zone-select>
      </a-form-model-item>
      <a-form-model-item label="开始时间" prop="started_at">
        <a-date-picker v-model="form.started_at" :allow-clear="false" placeholder="请选择开始时间" style="width: 100%;"></a-date-picker>
      </a-form-model-item>
      <a-form-model-item label="截止时间" prop="ended_at">
        <a-date-picker v-model="form.ended_at" :allow-clear="false" placeholder="请选择截止时间" style="width: 100%;"></a-date-picker>
      </a-form-model-item>
      <a-form-model-item label="系统负责人" prop="charger">
        <user-select v-model="form.charger" :allow-clear="false" :label-in-value="true" placeholder="请选择系统负责人"></user-select>
      </a-form-model-item>
      <a-form-model-item label="建设负责人" prop="builder">
        <user-select v-model="form.builder" :allow-clear="false" :label-in-value="true" placeholder="请选择建设负责人"></user-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import moment from 'moment'
import { getProject, updateProject } from '@/api/project'
import UserSelect from '@/components/select/UserSelect'
import ZoneSelect from '@/components/select/ZoneSelect'

const datetimeFormat = 'YYYY-MM-DD'

export default {
  name: 'UpdateForm',
  components: { UserSelect, ZoneSelect },
  data () {
    return {
      loading: false,
      visible: false,
      form: {
        name: '',
        zone: undefined,
        started_at: undefined,
        ended_at: undefined,
        charger: undefined,
        builder: undefined
      },
      rules: {
        name: [{ message: '请输入系统名称', required: true, trigger: 'blur' }],
        zone: [{ message: '请选择所属域', required: true, trigger: 'change' }],
        started_at: [{ message: '请选择开始时间', required: true, trigger: 'change' }],
        ended_at: [{ message: '请选择截止时间', required: true, trigger: 'change' }],
        charger: [{ message: '请选择系统负责人', required: true, trigger: 'change' }],
        builder: [{ message: '请选择建设负责人', required: true, trigger: 'change' }]
      },
      id: ''
    }
  },
  methods: {
    show (id) {
      this.id = id
      getProject(id).then(res => {
        const data = res.data
        this.form.name = data.name
        this.form.started_at = moment(data.started_at)
        this.form.ended_at = moment(data.ended_at)
        if (data.zone) this.form.zone = { key: data.zone.id, label: data.zone.name }
        if (data.charger) this.form.charger = { key: data.charger.id, label: data.charger.nickname }
        if (data.builder) this.form.builder = { key: data.builder.id, label: data.builder.nickname }
      }).finally(() => {
        this.visible = true
      })
    },
    reset () {
      this.form = {}
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = {
            name: this.form.name,
            zone_id: this.form.zone ? this.form.zone.key : undefined,
            started_at: this.form.started_at.format(datetimeFormat),
            ended_at: this.form.ended_at.format(datetimeFormat),
            charger_id: this.form.charger ? this.form.charger.key : undefined,
            builder_id: this.form.builder ? this.form.builder.key : undefined
          }
          this.loading = true
          updateProject(this.id, data).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
