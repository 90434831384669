<template>
  <a-modal :confirm-loading="loading" title="创建系统" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="系统名称" prop="name">
        <a-input v-model="form.name" :max-length="128" placeholder="请输入系统名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="所属域" prop="zone_id">
        <zone-select v-model="form.zone_id" placeholder="请选择所属域"></zone-select>
      </a-form-model-item>
      <a-form-model-item label="开始时间" prop="started_at">
        <a-date-picker v-model="form.started_at" :allow-clear="false" placeholder="请选择开始时间" style="width: 100%;"></a-date-picker>
      </a-form-model-item>
      <a-form-model-item label="截止时间" prop="ended_at">
        <a-date-picker v-model="form.ended_at" :allow-clear="false" placeholder="请选择截止时间" style="width: 100%;"></a-date-picker>
      </a-form-model-item>
      <a-form-model-item label="系统负责人" prop="charger_id">
        <user-select v-model="form.charger_id" :allow-clear="false" placeholder="请选择系统负责人"></user-select>
      </a-form-model-item>
      <a-form-model-item label="建设负责人" prop="builder_id">
        <user-select v-model="form.builder_id" :allow-clear="false" placeholder="请选择建设负责人"></user-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { createProject } from '@/api/project'
import UserSelect from '@/components/select/UserSelect'
import ZoneSelect from '@/components/select/ZoneSelect'

const datetimeFormat = 'YYYY-MM-DD'

export default {
  name: 'CreateForm',
  components: { UserSelect, ZoneSelect },
  data () {
    return {
      loading: false,
      visible: false,
      form: {},
      rules: {
        name: [{ message: '请输入系统名称', required: true, trigger: 'blur' }],
        zone_id: [{ message: '请选择所属域', required: true, trigger: 'change' }],
        started_at: [{ message: '请选择开始时间', required: true, trigger: 'change' }],
        ended_at: [{ message: '请选择截止时间', required: true, trigger: 'change' }],
        charger_id: [{ message: '请选择系统负责人', required: true, trigger: 'change' }],
        builder_id: [{ message: '请选择建设负责人', required: true, trigger: 'change' }]
      }
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    reset () {
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = { ...this.form }
          data.started_at = data.started_at.format(datetimeFormat)
          data.ended_at = data.ended_at.format(datetimeFormat)
          this.loading = true
          createProject(data).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
