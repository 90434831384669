import request from '@/utils/request'

const urlPrefix = '/zones'

export function getZoneList (params) {
  return request.get(urlPrefix, { params })
}

export function createZone (data) {
  return request.post(urlPrefix, data)
}

export function updateZone (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteZone (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getZone (id) {
  return request.get(`${urlPrefix}/${id}`)
}
