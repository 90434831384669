<template>
  <a-select
    :allow-clear="allowClear"
    :filter-option="false"
    :label-in-value="labelInValue"
    :mode="mode"
    :options="options"
    placeholder="请选择域"
    :show-search="true"
    :value="value"
    @change="v => $emit('input', v)"
    @popupScroll="popupScroll"
    @search="search"
  ></a-select>
</template>

<script>
import { getZoneList } from '@/api/zone'

export default {
  name: 'ZoneSelect',
  props: {
    allowClear: { type: Boolean, default: true },
    labelInValue: { type: Boolean, default: false },
    mode: { type: String, default: 'default' },
    value: { type: [Object, String] }
  },
  data () {
    return {
      form: { page: 1, page_size: 10 },
      options: [],
      total: 0,
      timeout: null
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getZoneList(this.form).then(res => {
        const data = res.data
        this.total = data.total
        data.data.forEach(zone => {
          this.options.push({ key: zone.id, label: zone.name })
        })
      })
    },
    popupScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop === clientHeight) {
        if (this.total > this.form.page * this.form.page_size) {
          this.form.page += 1
          this.fetch()
        }
      }
    },
    search (value) {
      this.options = []
      this.form.name = value
      if (this.timeout !== null) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.fetch()
      }, 300)
    }
  }
}
</script>
